<template>
  <Layout></Layout>
</template>

<script setup>
import { inject } from 'vue'
import Layout from '@/layout/index.vue'
import { getDateString, getNetInfo } from '@/utils/utils'
import { customFilter } from '@/utils/apm_error_filter'

try {
  const $apm = inject('$apm')
  $apm.addLabels({ group: 'global-miui-fe' })
  window.$apm = $apm
  window.$apm.setCustomContext({
    app_version: __APP_VERSION__,
    app_buildTime: getDateString(__APP_BUILD_TIME__),
    network: getNetInfo(),
  })
  $apm.addFilter((payload) => customFilter(payload))
  ;(function () {
    var hm = document.createElement('script')
    hm.innerHTML = `window.__app_version="${__APP_VERSION__}"; window.__app_buildTime="${getDateString(
      __APP_BUILD_TIME__
    )}"`
    var s = document.getElementsByTagName('script')[0]

    s.parentNode.insertBefore(hm, s)
  })()
} catch (e) {
  // eslint-disable-next-line
  console.error(e)
}
</script>
