import infoseekers2024Logo from '@/assets/images/siteLogos/infoseekers2024.png'
import infoseekers2024Background from '@/assets/images/siteLogos/infoseekers2024Background.png'
import newsearch2024Logo from '@/assets/images/siteLogos/newsearch2024.png'
import newsearch2024Background from '@/assets/images/siteLogos/newsearch2024Background.png'

import asksosearchLogo from '@/assets/images/siteLogos/asksosearch.png'
import asksosearchBg from '@/assets/images/siteLogos/asksosearchBackground.png'

import intelhubsearchLogo from '@/assets/images/siteLogos/intelhubsearch.png'
import intelhubsearchBackground from '@/assets/images/siteLogos/intelhubsearchBackground.png'

import infonowsearchLogo from '@/assets/images/siteLogos/infonowsearch.png'
import infonowsearchBackground from '@/assets/images/siteLogos/infonowsearchBackground.png'

/**
 * 顶部logo和背景颜色配置文件
 * top_background_color: 顶部背景颜色
 * top_text: 顶部文字
 * top_logo: 顶部logo
 * banner_back_img: 顶部背景图片
 * banner_button_background_color: 顶部按钮背景颜色
 * inputBorderCorlor: 输入框边框颜色
 * placeholder: 输入框提示文字
 * searchBoxClassName: 搜索框样式名
 * favicon: 网站图标
 */

/**
 * searchBoxClassName：
 *  round: 圆角
 *  square: 方形
 *  mr10: margin-right = 10px
 *  border: 带边框
 */

export const Banner_Infos = {
  default: {
    top_background_color: '#A54EAD',
    top_text: 'infoseekers2024',
    top_logo: infoseekers2024Logo,
    banner_back_img: infoseekers2024Background,
    banner_button_background_color: '#BB4A51',
    favicon: '/favicon.ico',
  },
  'infoseekers2024.com': {
    top_background_color: '#A54EAD',
    top_text: 'infoseekers2024',
    top_logo: infoseekers2024Logo,
    banner_back_img: infoseekers2024Background,
    banner_button_background_color: '#BB4A51',
    favicon: '/favicon.ico',
  },
  'newsearch2024.com': {
    top_background_color: '#54883C',
    top_text: 'Newsearch2024',
    top_logo: newsearch2024Logo,
    banner_back_img: newsearch2024Background,
    banner_button_background_color: '#54883C',
    favicon: '/favicon.ico',
  },
  'asksosearch.com': {
    top_background_color: '#817A7C',
    top_text: 'Asksosearch.com',
    top_logo: asksosearchLogo,
    banner_back_img: asksosearchBg,
    banner_button_background_color: '#e269b2',
    inputBorderCorlor: '#e269b2',
    placeholder: 'Search',
    searchBoxClassName: 'square mr10 border',
    favicon: '/asksosearch.png',
  },
  'intelhubsearch.com': {
    top_background_color: '#df9664',
    top_text: 'Intelhubsearch.com',
    top_logo: intelhubsearchLogo,
    banner_back_img: intelhubsearchBackground,
    banner_button_background_color: '#9f3676',
    inputBorderCorlor: '#9f3676',
    placeholder: 'Search',
    searchBoxClassName: 'round mr10 border',
    favicon: '/intelhubsearch.png',
  },
  'infonowsearch.com': {
    top_background_color: '#1f928b',
    top_text: 'InfoNowsearch.com',
    top_logo: infonowsearchLogo,
    banner_back_img: infonowsearchBackground,
    banner_button_background_color: '#f86250',
    inputBorderCorlor: '#f86250',
    placeholder: 'Search',
    searchBoxClassName: 'square mr10 border',
    favicon: '/infonowsearch.png',
  },
}
