import '@/utils/intersection-observer'
import Vue3Lottie from 'vue3-lottie'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import i18n from './locales'

import eventTrackManager from '@/utils/eventTrackManager'
import { initElasticApm } from '@/utils/elasticApm'
import initExposureDirective from '@/utils/exposureCheck'

import './assets/styles/reset.css'
import './assets/styles/weather.css'
import { afgManager } from '@/utils/ads'

import { addWebpSuffix } from '@/utils/webp'
import LazyLoadImage from './components/LazyloadImage/index.js'
import '@/utils/favicon'

eventTrackManager.init()

afgManager.init()

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(Vue3Lottie)
app.use(LazyLoadImage)
initElasticApm(app, router)
initExposureDirective(app)

app.mount('#app')

app.config.globalProperties.$filters = {
  // 图片webp
  image2webp(src) {
    return addWebpSuffix(src)
  },
}
