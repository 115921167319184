import { Banner_Infos } from '@/components/BannerLogo/const.js'

export const setFavicon = () => {
  const currentDomain = window.location.hostname
  const currentFavicon = (Banner_Infos[currentDomain] || Banner_Infos.default)
    .favicon
  if (currentFavicon) {
    const link = document.querySelector("link[rel*='icon']")
    link.href = currentFavicon
  }
}

setFavicon()
