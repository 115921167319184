const error_blacklist = {
  // 错误类型
  type: (() => {
    return [
      'NotSupportedError',
      'AbortError',
      'SecurityError',
      'NetworkError',
      'InvalidStateError',
      'InvalidAccessError',
      'SyntaxError',
      'HierarchyRequestError',
      'InvalidCharacterError',
      'NotFoundError',
      'DataCloneError',
      'UnknownError',
      'TimeoutError',
      'FirebaseError',
      'Unhandled Promise Rejection',
      'Uncaught RangeError',
    ].map((item) => item.toLowerCase())
  })(),
  // 错误信息
  msg: (() => {
    return [
      'Script error.',
      'timeout of 6000ms exceeded',
      'createBaseVNode is not defined',
      'Failed to fetch',
      'Network Error',
    ].map((item) => item.toLowerCase())
  })(),
}

export const customFilter = (payload) => {
  const { errors = [] } = payload
  const newErr =
    errors.filter((item) => {
      const {
        exception: { message, type = '' },
      } = item || {}
      const { type: errType, msg } = error_blacklist
      if (
        (type && errType.includes(type.toLowerCase())) ||
        msg.some((str) => message.toLowerCase().indexOf(str) > -1)
      ) {
        return false
      }
      return true
    }) || []

  return {
    ...payload,
    errors: newErr,
  }
}
